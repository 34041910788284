/* General styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom, #ffffff, #f3f7fa);
  color: #1b2945;
}

/* Hero section */
.home-page .hero {
  background: linear-gradient(120deg, #1b2945, #003d7a);
  color: white;
  text-align: center;
  padding: 50px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.home-page .hero h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.home-page .hero p {
  font-size: 18px;
  margin-top: 0;
}

/* Team image section */
.home-page .team-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

.home-page .team-image {
  max-width: 80%;
  height: auto;
  border: 5px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-page .team-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
