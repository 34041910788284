/* Style the entire player list container */
div.player-list {
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(120deg, #f0f0f0, #ffffff);
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Style the heading */
div.player-list h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #003d7a;
  margin-bottom: 20px;
}

/* Use grid for the unordered list */
div.player-list ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style each list item */
div.player-list ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e9eff6;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

div.player-list ul li:hover {
  transform: scale(1.05);
  background-color: #d7e3f5;
}

/* Style the link */
div.player-list ul li a {
  text-decoration: none;
  color: #003d7a;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

div.player-list ul li a:hover {
  color: #1a73e8;
}

/* Style the player image */
div.player-list ul li img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #d9d9d9;
}
