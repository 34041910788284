/* Header container */
header.header {
  background: linear-gradient(90deg, #1b2945, #001233);
  color: white;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Content alignment */
header .header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

/* Club logo */
header .club-logo {
  width: 60px;
  height: auto;
}

/* Header title */
header h1 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

/* Navigation menu */
header .nav-menu {
  display: flex;
  gap: 15px;
}

header .nav-menu a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s ease;
}

header .nav-menu a:hover {
  color: #1a73e8;
}
