/* Main container */
div.player-detail {
  max-width: 600px;
  margin: 30px auto;
  background: linear-gradient(120deg, #e0f7fa, #ffffff);
  border: 2px solid #cfd8dc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  text-align: center;
}

/* Back button */
div.player-detail button {
  background-color: #1e88e5;
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

div.player-detail button:hover {
  background-color: #1565c0;
}

/* Player image */
div.player-detail img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid #cfd8dc;
  margin: 20px 0;
}

/* Player name */
div.player-detail h2 {
  font-size: 28px;
  color: #00796b;
  margin-bottom: 10px;
}

/* Details text */
div.player-detail p {
  font-size: 16px;
  color: #455a64;
  margin: 10px 0;
}

/* Highlight key labels */
div.player-detail p strong {
  color: #004d40;
}

/* Buttons container */
div.player-detail .buttons-container {
  margin-top: 20px;
}

/* Google search button */
div.player-detail .buttons-container button {
  background-color: #fbc02d;
  color: #212121;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

div.player-detail .buttons-container button:hover {
  background-color: #f9a825;
  transform: translateY(-3px);
}
